import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import { graphql } from "gatsby"
import Posts from "../components/Posts"
const PostsPage = ({ data }) => {
  const {
    allMdx: { nodes: posts },
  } = data
  return (
    <Layout>
      <Hero />
      <Posts posts={posts} title=" All Products" />
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          title
          readTime
          category
          date(formatString: "MMM Do, YYYY")
          author
          slug
          image
          keywords
        }
        excerpt
        id
      }
    }
  }
`

// export const postListQry = graphql`
//   query postListQry($skip: Int!, $limit: Int!) {
//     allMdx(
//       sort: { fields: [frontmatter___date], order: DESC }
//       limit: $limit
//       skip: $skip
//     ) {
//       edges {
//         node {
//           id
//           frontmatter {
//             title
//             date(formatString: "MMMM Do YYYY")
//             author
//             image
//             slug
//           }
//           excerpt
//         }
//       }
//     }
//   }
// `

// image {
//   childImageSharp {
//     fluid {
//       ...GatsbyImageSharpFluid
//     }
//   }
//}
export default PostsPage
